module.exports = {
  title: `Jonathan's Dev Blog`,
  description: `Making stuff with code`,
  author: `Jonathan Perry`,
  introduction: `Fullstack dev - I like making products fast`,
  siteUrl: `https://www.learningsomethingnew.com`, // Your blog site url
  social: {
    twitter: `johnnymakestuff`, // Your Twitter account
    github: `syonip`, // Your GitHub account
    medium: `@syonip`, // Your Medium account
    facebook: ``, // Your Facebook account
  },
  icon: `content/assets/felog.png`, // Add your favicon
  keywords: [`blog`],
  comment: {
    disqusShortName: '', // Your disqus-short-name. check disqus.com.
    utterances: 'syonip/blog-comments', // Your repository for archive comment
  },
  configs: {
    countOfInitialPost: 30, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: 'bjEeAGtJ6',
  },
  share: {
    facebookAppId: '', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: 'UA-76181767-3', // Add your google analytics tranking ID
}
